<template>
  <div>
    <KTCard ref="preview" v-bind:title="title">

      <!--页面标题-->
      <template v-slot:title>
        <h3 class="card-title">
          <b-icon icon="blockquote-right" class="mx-3"></b-icon>
          角色管理
        </h3>
      </template>

      <!--操作按钮组-->
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <!--按钮组-->
          <b-button variant="primary" @click="create" class="mx-2 ">
            <b-icon icon="plus"></b-icon>
            新增
          </b-button>
          <b-button variant="success" @click="edit()" class="mx-2">
            <b-icon icon="pencil"></b-icon>
            编辑
          </b-button>
          <b-button variant="danger" @click="del()" class="mx-2">
            <b-icon icon="x"></b-icon>
            删除
          </b-button>
          <!--按钮组-->
        </div>
      </template>

      <!--数据表格-->
      <template v-slot:body>
        <div style="min-height: 70vh">
          <!--表格-->
          <v-data-table
              v-model="table.selected"
              :headers="table.headers"
              :items="table.desserts"
              :single-select="table.singleSelect"
              :footer-props="table.footer"
              :server-items-length="table.itemsCount"
              :options.sync="table.pagination"
              :no-data-text="table.noDataText"
              :loading="table.loading"
              :loading-text="table.loadingText"
              item-key="roleId"
              show-select
          >
            <template v-slot:item.status="{ item }">
              <h6>
                <b-badge variant="success" v-if="item.status==1">正常</b-badge>
                <b-badge variant="danger" v-else>禁用</b-badge>
              </h6>
            </template>

            <template v-slot:item.action="{ item }">
              <v-btn small text icon fab v-b-tooltip.hover.lefttop  title="编辑角色" v-on:click="edit(item.roleId)">
                <v-icon>la la-edit</v-icon>
              </v-btn>
              <v-btn small text icon fab v-b-tooltip.hover.lefttop  title="删除角色" v-on:click="del(item.roleId)">
                <v-icon>la la-trash</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </div>
      </template>
    </KTCard>

  </div>
</template>

<script>
import KTCard from "@/view/content/Card.vue";
import KTUtil from "@/assets/js/components/util";

import ApiService from "@/core/services/api.service";

// FormValidation plugins
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import Swal from "sweetalert2";

export default {
  name: "List",
  components: {
    KTCard
  },
  props: {
    title: String
  },
  data() {
    return {
      filter: {
        selected: null
      },
      table: {
        loadingText: "正在加载...",
        noDataText: "暂无数据...",
        loading: false,
        singleSelect: false,
        selected: [],
        itemsCount: 10,
        headers: [
          {
            text: "角色名称",
            align: "left",
            sortable: false,
            value: "roleName"
          },
          {text: "角色编码", value: "roleCode"},
          {text: "描述", value: "roleDesc"},
          {text: "状态", value: "status"},
          {text: "操作", value: "action"},
        ],
        footer: {
          itemsPerPageOptions: [5, 10, 20, 50, 100],
          itemsPerPageText: "分页数量",
          showCurrentPage: true
        },
        pagination: {},
        desserts: []
      },
    };
  },
  mounted() {

  },
  created() {

  },
  methods: {
    create() {
      this.$router.push({
        path: "/role/edit"
      })
    },
    edit(roleId = undefined) {
      roleId = roleId == undefined ? this.getSelectedIds() : [roleId];
      if (roleId.length != 1) {
        Swal.fire({
          title: "",
          text: "请选择要编辑的数据行,一次仅且只能选择一条!",
          icon: "warning",
          confirmButtonClass: "btn btn-success",
          heightAuto: false
        });
        return;
      }
      this.$router.push({
        path: "/role/edit",
        query: {
          roleId: roleId[0]
        }
      });
    },

    del(roleId = undefined) {
      roleId = roleId == undefined ? this.getSelectedIds() : [roleId];
      if (roleId.length < 1) {
        Swal.fire({
          title: "",
          text: "请选择要删除的数据行!",
          icon: "warning",
          confirmButtonClass: "btn btn-success",
          heightAuto: false
        });
        return;
      }

      let that = this;
      Swal.fire({
        title: "确定删除?",
        icon: "warning",
        buttons: true,
        showConfirmButton: true,
        confirmButtonText: "确定",
        showCancelButton: true,
        cancelButtonText: "取消",
        dangerMode: true,
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          ApiService.post("/api/role/delete", roleId).then(({data}) => {
            if (data.success) {
              that.getData();
              Swal.fire({
                title: "",
                text: "删除成功!",
                icon: "success",
                confirmButtonClass: "btn btn-success",
                heightAuto: false
              });
            } else {
              Swal.fire({
                title: "删除失败！",
                text: data.message,
                icon: "warning",
                confirmButtonClass: "btn btn-success",
                heightAuto: false,
                timer: 3000
              });
            }
          })
        }
      });

    },

    getData() {
      ApiService.query("/api/role/query_role_list", {
        params: {
          "pageNum": this.table.pagination.page,
          "pageSize": this.table.pagination.itemsPerPage
        }
      }).then(({data}) => {
        this.table.desserts = data.result.data;
        this.table.itemsCount = data.result.total;
      })
    },
    getSelectedIds() {
      let ids = new Array();
      if (this.table.selected != null && this.table.selected != undefined) {
        this.table.selected.forEach(function (item) {
          ids.push(item.roleId);
        })
      }
      return ids;
    }
  },
  computed: {
    pagination: function () {
      return this.table.pagination
    }
  },
  watch: {
    pagination: {
      handler() {
        this.getData()
      },
      deep: true,
    },
  },
};
</script>
